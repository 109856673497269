import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import ReactGA from "react-ga4";
import {
    onAuthStateChanged,
    signInWithPhoneNumber,
    signOut,
} from "firebase/auth";
import { auth } from "../firebase";
import { getCurrentUserApi } from "../API/users/getCurrentUser";
import { updateUserApi } from "../API/users/updateUser";
import { refreshTokenApi } from "../API/users/refreshToken";
import { deleteUserApi } from "../API/users/deleteUser";
import { registerUserApi } from "../API/users/registerUser";
import LocalStorage from "../Helpers/LocalStorage";
import { updateUserProfilePicturesApi } from "../API/users/setUserProfilePicture";
import { getTranslationsLanguagesAPI } from "../API/users/getTranslationsLanguages";
import { logoutUserAPICAll } from "../API/users/logoutUser";
import { useCookies } from "react-cookie";
import { Purchases } from "@revenuecat/purchases-js";
import { cancelStripeSubscriptionAPICall, getStripePaymentLinkAPICall, hadFreeTrialAPICall } from "../API/payments/paymentsService";
import { unregister } from "../serviceWorker";

export const AuthContext = createContext({
    user: [],
    userCustomerInfo: [],
    isAuthenticated: [],
    translationsLanguages: [],
    signInWithPhone: () => { },
    verifyOtpCodeAndSignIn: () => { },
    createUser: () => { },
    getCurrentUser: () => { },
    singOut: () => { },
    updateUser: () => { },
    refreshToken: () => { },
    deleteUser: () => { },
    updateUserProfilePictures: () => { },
    getTranslationsLanguages: () => { },
    handleLoginUser: () => { },
    updateUserWithNewPushToken: () => { },
    setPushToken: () => { },
    pushToken: [],
    hadFreeTrial: () => { },
    getStripePaymentLink: () => { },
    cancelStripeSubscription: () => { },
    checkSubscriptions: () => { },
    singOutFirebase: () => { },
});

const AuthContextProvider = ({ children }) => {
    const [cookies, setCookie] = useCookies(['notificationsModal']);
    const [pushToken, setPushToken] = useState(null)
    const [user, setUser] = useState(undefined);
    const [translationsLanguages, setTranslationsLanguages] = useState([]);
    const [userCustomerInfo, setUserCustomerInfo] = useState(null);


    const REVENUE_CAT_KEY = process.env.REACT_APP_REVENUE_CAT_KEY

    const handleLoginUser = (user) => {
        setUser(user);
    };

    const handleLogoutUser = () => {
        // SET GA ANALYTICS userID null
        ReactGA.set({ user_id: null })
        setUser(null);
        LocalStorage.removeAuthenticationToken();
        LocalStorage.removeRefreshToken();
    };


    const checkSubscriptions = async () => {
        try {

            const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
            setUserCustomerInfo(customerInfo)
            // access latest customerInfo
        } catch (e) {
            console.log(e)
            // Handle errors fetching customer info
        }
    }


    const signInWithPhone = (phoneNumber, appVerifier, onSucces, onError) => {
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                onSucces(confirmationResult);
                //   window.confirmationResult = confirmationResult;
                // ...
            })
            .catch((error) => {
                onError(error);
            });
    };

    const verifyOtpCodeAndSignIn = (
        confirmationResult,
        code,
        onSucces,
        onError
    ) => {
        confirmationResult
            .confirm(code)
            .then((result) => {
                const user = result.user;
                onSucces(result);
                // ...
            })
            .catch((error) => {
                onError(error);
                // User couldn't sign in (bad verification code?)
                // ...
            });
    };

    const refreshToken = useCallback(
        async (refreshToken, onSucces, errorCallback) => {
            try {
                await refreshTokenApi(
                    refreshToken,
                    function (response) {
                        onSucces(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback();
            }
        }
    );

    const singOutFirebase = () => {
        setCookie('notificationsModal', null)
        signOut(auth)
            .then(() => {
                const refresh_token = LocalStorage.getRefreshToken();
                if (refresh_token && refresh_token != "undefined") {
                    handleSignOutUser()
                } else {
                    handleLogoutUser()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const handleSignOutUser = () => {
        signOutUser(
            () => {
                handleLogoutUser();
            },
            (error) => {
                console.log(error)
            }
        )
    }


    // const authObserver = () => {
    //     onAuthStateChanged(auth, (authUser) => {

    //         if (authUser) {
    //             // User is signed in, see docs for a list of available properties
    //             // https://firebase.google.com/docs/reference/js/auth.user
    //             const uid = authUser.uid;
    //             LocalStorage.setAuthenthicationToken(authUser.accessToken);
    //             LocalStorage.setRefreshToken(authUser.stsTokenManager.refreshToken);
    //             // handleLoginUser(user);
    //             if (!user) {

    //                 getCurrentUser(
    //                     function () { },
    //                     function () {
    //                         setUser(null)
    //                     }
    //                 );
    //             }
    //         } else {
    //             singOut()
    //             // handleLogoutUser();
    //             // User is signed out
    //             // ...
    //         }
    //     });
    // };

    const singOut = () => {

        setCookie('notificationsModal', null)
        signOut(auth)
            .then(() => {

                const refresh_token = LocalStorage.getRefreshToken();
                if (refresh_token && refresh_token != "undefined") {
                    signOutUser(
                        () => {

                            handleLogoutUser();
                        },
                        (error) => {

                            console.log(error)
                        }

                    )
                } else {

                    handleLogoutUser()
                }

            })
            .catch((error) => {

                console.log(error);
            });
    };

    const getCurrentUser = useCallback(async (onSucces, errorCallback) => {

        try {
            await getCurrentUserApi(
                function (response) {
                    handleLoginUser(response.data.user);
                    //SET USER ID FOR GA ANALYTICS 
                    const user_id = response.data.user.id;
                    ReactGA.set({ user_id: user_id })
                    const purchases = Purchases.configure(REVENUE_CAT_KEY, user_id.toString());
                    checkSubscriptions();
                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback();
        }
    });

    const getTranslationsLanguages = useCallback(async (onSucces, errorCallback) => {
        try {
            await getTranslationsLanguagesAPI(
                function (response) {
                    onSucces(response);
                    setTranslationsLanguages(response.data.languages)
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback();
        }
    });

    const createUser = useCallback(
        async (
            data,
            onSuccess,
            errorCallback
        ) => {
            try {
                await registerUserApi(
                    data,
                    function (response) {
                        handleLoginUser(response.data.user)
                        const user_id = response.data.user.id;
                        const purchases = Purchases.configure(REVENUE_CAT_KEY, user_id.toString());
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const updateUser = useCallback(
        async (
            data,
            onSuccess,
            errorCallback
        ) => {
            try {
                await updateUserApi(
                    data,
                    function (response) {
                        handleLoginUser(response.data.user);
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const deleteUser = useCallback(async (reasons, onSucces, errorCallback) => {
        try {
            await deleteUserApi(
                reasons,
                function (response) {
                    signOut(auth)
                        .then(() => {
                            handleLogoutUser();
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    onSucces(response);
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback(err);
        }
    });

    const updateUserProfilePictures = useCallback(
        async (
            data,
            onSuccess,
            errorCallback
        ) => {
            try {
                await updateUserProfilePicturesApi(
                    data,
                    function (response) {
                        handleLoginUser(response.data.user);
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const signOutUser = useCallback(
        async (
            onSuccess,
            errorCallback
        ) => {
            try {
                await logoutUserAPICAll(
                    pushToken,
                    function (response) {
                        setPushToken(null);
                        onSuccess(response);
                        unregister();
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const hadFreeTrial = useCallback(
        async (
            onSuccess,
            errorCallback
        ) => {
            try {
                await hadFreeTrialAPICall(

                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const getStripePaymentLink = useCallback(
        async (
            onSuccess,
            errorCallback
        ) => {
            try {
                await getStripePaymentLinkAPICall(

                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const cancelStripeSubscription = useCallback(
        async (
            onSuccess,
            errorCallback
        ) => {
            try {
                await cancelStripeSubscriptionAPICall(

                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );


    const updateUserWithNewPushToken = (token) => {
        var objectData = {};
        objectData.push_token = token
        objectData.push_token_platform = "web"
        updateUser(
            objectData,
            (response) => {

            },
            (error) => {

            },
        )

    }


    // useEffect(() => {
    //     authObserver();
    // }, []);


    return (
        <AuthContext.Provider
            value={{
                user,
                userCustomerInfo,
                signInWithPhone,
                verifyOtpCodeAndSignIn,
                createUser,
                getCurrentUser,
                singOut,
                updateUser,
                refreshToken,
                deleteUser,
                updateUserProfilePictures,
                getTranslationsLanguages,
                translationsLanguages,
                handleLoginUser,
                updateUserWithNewPushToken,
                setPushToken,
                pushToken,
                hadFreeTrial,
                getStripePaymentLink,
                cancelStripeSubscription,
                checkSubscriptions,
                singOutFirebase
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthService = () => useContext(AuthContext);

export default AuthContextProvider;
