import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as QuickChatIcon } from "../../Assets/icons/quickChat/QuickChatIcon.svg";
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as New } from "../../Assets/icons/newChatIcon.svg";
import { useNavigate } from 'react-router';
import { useQuickChatService } from '../../Context/QuickChatContext';
import { isMobile, isSafari } from 'react-device-detect';


const QuickChatHeader = ({ onClick }) => {



    const navigate = useNavigate();
    const { quickChat } = useQuickChatService()

    let registerText = "   Register on emotii to create chats"
    if (isMobile) {
        registerText = "   Register on emotii"
    }



    return (
        <Container onClick={onClick} id='qc-header'  >
            <QuickChatIcon />
            <QcName>
                {quickChat?.quick_chat_name}
            </QcName>
            <PrimaryBtn
                onClick={(e) => window.open("https://7jkwc.app.link/redirect-quick-chats")}>
                <New />
                {registerText}
            </PrimaryBtn>
        </Container>
    )
}

export default QuickChatHeader

const Container = styled.div`
display:flex;
align-items:center;
gap:10px;
padding: 16px;
min-height:74px;
cursor:pointer;
max-height:74px;
overflow:hidden;
background:${colors.userMenuHeaderBg};
position:absolute;
top:0;
z-index:2;
width:100%;
`
const QcName = styled.h1`
color:${colors.white};
font-size: 20px;
font-weight: 600;
line-height: 30px;

@media(max-width:425px){
    font-size: 18px;
    line-height: 26px;
    text-align:center;
}

`
const PrimaryBtn = styled.button`
display: flex;
align-items: center;
justify-content:center;
gap: 3px;
background: ${colors.purple};
color: ${colors.white};
font-size: 16px;
font-weight: 400;
line-height: 22px;
border: none;
border-radius: 10px;
padding: 10px 20px;
cursor: pointer;
width:fit-content;
margin-left:auto;
svg {
  height: 20px;
  width: 20px;
}

&:disabled{
    cursor:inherit;
    opacity:0.3;
}
@media(max-width:990px){
    padding: 8px;
    font-size: 12px;
    line-height: 18px;
}
@media(max-width:450px){

    font-size: 12px;
    line-height: 14px;
}
`;
