import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as CheckSvg } from "../../Assets/icons/Check.svg"
import { useAuthService } from '../../Context/AuthContext';
import moment from 'moment';
import DeleteConfirmationChild from '../GenericComponents/DeleteConfirmationChild';

const BenefitElement = ({ text, description }) => {
    return (
        <BenefitElementWrapper>
            <CheckSvg />
            <BenefitTextWrapper>
                <h1>{text}</h1>
                <p>{description}</p>
            </BenefitTextWrapper>
        </BenefitElementWrapper>
    )
}
const SubscriptionModal = ({ handleClose, handleSubscribeClick, handleUnSubscribeClick }) => {

    const { hadFreeTrial, userCustomerInfo } = useAuthService();

    const [userHadFreeTrial, setUserHadFreeTrial] = useState(false);
    const [objKeys, setObjKeys] = useState([]);
    const [subscription, setSubscription] = useState(null)
    const [openConfirmation, setOpenConfirmation] = useState(false)

    const SubscribeTitle = "Subscribe Now"
    const SubscribedTitle = "Subscription Details"

    const JoinText = "Join emotii PRO for seamless audio calls and live translations. Effortless conversations."
    const JoinedTex = "Thank you for being an emotii PRO user, you can enjoy seamless audio calls and live translations. Effortless conversations."

    const navigateToLandingPageLink = (to) => {
        let url = "http://emotii.ai"
        window.open(`${url}/${to}`);
    };

    const handleHadFreeTrial = () => {
        hadFreeTrial(
            (response) => {
                const resp = response.data.had_free_trial
                setUserHadFreeTrial(resp)
            },
            (error) => { console.log(error) }
        )
    }

    const handleKeys = () => {
        if (!userCustomerInfo) {
            return
        }

        let objKeys = Object.keys(userCustomerInfo.entitlements.active)
        setObjKeys(objKeys)
    }


    const benefitList = [

        {
            text: "Seamless Audio Calls",
            description: "Enjoy high-quality audio calls without interruptions."
        },

        {
            text: "Live Audio Translations",
            description: "Speak your language, and the other person hears you in theirs."
        },

        {
            text: "No Language Barriers",
            description: "Communicate effortlessly with anyone, regardless of language."
        },

        {
            text: "Enhanced Communication",
            description: "Connect more deeply with clear, real-time translations."
        },

        {
            text: "Global Reach",
            description: "Expand your connections worldwide without the need to learn new languages."
        },
    ]


    useEffect(() => {
        if (!userCustomerInfo) {
            return
        }

        setSubscription(userCustomerInfo.entitlements.active)
        handleKeys();

    }, [userCustomerInfo])


    useEffect(() => {

        handleHadFreeTrial();
    }, [])

    return (
        <FullScreenItem>
            <Card>
                <Line>
                    <Title>
                        {objKeys.length > 0 ?
                            SubscribedTitle :
                            SubscribeTitle
                        }
                    </Title>
                    <CloseBtn onClick={handleClose}>
                        <CloseIcon />
                    </CloseBtn>
                </Line>

                <Line>
                    <InfoText>  {objKeys.length > 0 ?
                        JoinedTex :
                        JoinText
                    }</InfoText>
                </Line>
                <Line>
                    <SubscriptionPlan>
                        <PlanPrice>
                            <h1>$0.99/<span>Month</span></h1>
                            <p>After trial</p>
                        </PlanPrice>
                        <CheckSvg style={{ marginLeft: "auto", width: "20px" }} />
                    </SubscriptionPlan>
                </Line>

                <Line>
                    <InfoText>Benefits</InfoText>
                </Line>
                <BenefitsList>
                    {benefitList.map((element, index) => (
                        <BenefitElement
                            key={index}
                            text={element.text}
                            description={element.description}
                        />
                    ))}

                </BenefitsList>
                <Line>
                    {objKeys.length > 0 ?

                        (subscription.Pro.willRenew ?
                            (subscription.Pro.store === "stripe" &&
                                <DeleteConfirmationChild
                                    action={handleUnSubscribeClick}
                                    setOpen={setOpenConfirmation}
                                    open={openConfirmation}
                                    variant={"unsubscribe"}
                                >
                                    <CustomBtn onClick={() => setOpenConfirmation(true)}>
                                        Unsubscribe
                                    </CustomBtn>

                                </DeleteConfirmationChild>)


                            :
                            <InfoText style={{ color: colors.purple }}>
                                You have an active subscription until {moment(subscription.Pro.expirationDate).format("DD/MM/YYYY")}
                            </InfoText>
                        )


                        : <CustomBtn onClick={() => handleSubscribeClick()}>
                            {userHadFreeTrial ? "Subscribe" : "Start 3-Days Free Trial"}
                        </CustomBtn>}

                </Line>
                <Line >
                    <TermsBtn onClick={() => navigateToLandingPageLink("emotii-platform-terms-and-conditions/")}>
                        Terms & Conditions
                    </TermsBtn>
                </Line>
            </Card>

        </FullScreenItem >
    )
}

export default SubscriptionModal


const FullScreenItem = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:fixed;
width:100%;
height:100%;
background:rgba(0,0,0,0.3);
left:0;
top:0;
z-index:2;
`
const Card = styled.div`
display:flex;
flex-direction:column;
min-height:200px;
max-width:500px;
width:100%;
background:${colors.newChatModalBg};
border-radius:8px;
padding:30px;

position:relative;
gap:20px;
`
const Line = styled.div`
display:flex;
width:100%;
gap:20px;
align-items:center;
svg{
max-height:40px;
width:fit-content;
}
`

const Title = styled.h1`
font-size:20px;
font-weight:500;
line-height:24px;
color:${colors.chatElementColor};

`

const CustomBtn = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:100%;
color:${colors.white};
border-radius:10px;
border:none;
background:${props => props.outlined ? "none" : colors.darkPurple};
padding:14px;
cursor:pointer;
border:1px solid ${colors.darkPurple};
font-size: 16px;
font-weight: 600;
line-height: 22px;
&:hover{
background:${props => props.outlined ? colors.chatsListBg : colors.purple};
border:1px solid ${colors.purple};
}
`

const CloseBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
margin-left:auto;
max-width:32px;

`
const InfoText = styled.h1`
font-size:14px;
line-height:22px;
font-weight:400;
color:${colors.chatElementColor};
`
const SmallInfor = styled(InfoText)`
font-size:12px;
line-height:18px;
`

const SubscriptionPlan = styled.div`
display:flex;
padding:16px;
border-radius:10px;
background:${colors.chatElementBgSelected};
width:100%;
align-items:center;
`
const PlanPrice = styled.div`
display:flex;
flex-direction:column;
gap:5px;

h1{
font-size: 22px;
font-weight: 800;
line-height: 22px;
color:${colors.white};
span{
font-weight:400;
}
}
p{
font-size: 12px;
font-weight: 500;
line-height: 12px;
color:${colors.chatElementColor};
}
`
const BenefitsList = styled.div`
display:flex;
flex-direction:column;
gap:15px;
`
const BenefitElementWrapper = styled.div`
display:flex;
align-items:start;
gap:10px;
`
const BenefitTextWrapper = styled.div`
display:flex;
flex-direction:column;
gap:5px;
h1{
font-size: 16px;
font-weight: 600;
line-height: 19.36px;
color:${colors.white};
}
p{
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
color:${colors.chatElementColor};
}
`
const TermsBtn = styled(SmallInfor)`
cursor:pointer;
color:${colors.purple};
`