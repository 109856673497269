import React from 'react'
import LoginPage from '../Pages/LoginPage';
import { useAuthService } from '../Context/AuthContext';
import { Navigate, useLocation } from 'react-router';

const PrivateRoute = ({ children }) => {
    const location = useLocation();


    const { user } = useAuthService();
    if (user === undefined) return <div></div>
    if (user === null) {
        return (
            <Navigate to={"/login"} state={{ backTo: `${location.pathname}` }} />
        );

    }



    return <>{children}</>;

}

export default PrivateRoute