import { useCallback, useEffect, useState } from "react"

export const useDefaultCountry = () => {

    const [defaultCountry, setDefaultCountry] = useState()

    useEffect(() => {
        fetch('https://geolocation-db.com/json/')
            .then(response => response.json())
            .then(data => {
                setDefaultCountry(data.country_code)

            })
            .catch(error => console.log(error))
    }, [])

    return [defaultCountry]
}