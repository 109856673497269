import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import UserMenu from "../Components/GenericComponents/UserMenu";
import { colors } from "../Assets/styles/colors";
import ConversationHeader from "../Components/Chat/ConversationHeader";
import ConversationView from "../Components/Chat/ConversationView";
import ProfileOptions from "../Components/ProfileComponents/ProfileOptions";
import { useConversationService } from "../Context/ConversationsContext";
import { notifyError, notifySuccess } from "../Helpers/Notifications";
import moment from "moment";
import { useAuthService } from "../Context/AuthContext";
import EmptyConversation from "../Components/Chat/EmptyConversation";
import WebSocketObserver from "../Helpers/websocket/WebSocketObserver";
import { useWebSocketDataService } from "../Context/WebsocketContext";
import {
  findTranslatedTextForUser,
  handleConvertBitesToMb,
  handleReceivedMessages,
  sortNewestConversations,
  sortReceivedMessages,
} from "../Helpers/chatHelper/ChatHelper";
import ConversationsList from "../Components/Chat/ConversationsList";
import { mapEmoji } from "../Constants/emojiList";
import FullscreenImage from "../Components/Chat/FullscreenImage";
import RightClickMenu from "../Components/Chat/RightClickMenu";
import { useContextMenu } from "react-contexify";
import ChatDetailsHeader from "../Components/Chat/ChatDetailsHeader";
import RatingModal from "../Components/GenericComponents/RatingModal";
import GroupDetailsView from "../Components/GroupDetails/GroupDetailsView";
import ChatDetailsView from "../Components/Chat/ChatDetailsView";
import LocalStorage from "../Helpers/LocalStorage";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";

import { localizationStrings } from "../Localization/Localization";
import ForwardModal from "../Components/Chat/ForwardModal";
import AddNewGroup from "../Components/NewGroup/AddNewGroup";

import AddNewChatContact from "../Components/NewChat/AddNewContact";
import AddNewChat from "../Components/NewChat/AddNewChat";
import { cloneDeep, last } from "lodash";
import copy from "copy-to-clipboard";
import SendMedia from "../Components/Chat/SendMedia";
import SendFile from "../Components/Chat/SendFile";
import AddQuickChat from "../Components/QuickChat/AddQuickChat";
import QcModal from "../Components/QuickChat/QcModal";
import QcChatDetailsView from "../Components/QuickChat/QcChatDetailsVie";
import FullScreenCall, {
  callingSoundHowl,
} from "../Components/AudioVideoCalls/FullScreenCall";
import { CallsContext, useCallsService } from "../Context/CallsContext";
import { CALLS_LIST, MESSAGES_LIST, NEUTRAL_MOOD, SUPPORTIVE_MOOD } from "../Constants/constantValues";
import ToggleList from "../Components/GenericComponents/ToggleList";
import CallsList from "../Components/AudioVideoCalls/CallsList";
import CallModal from "../Components/AudioVideoCalls/CallModal";
import { useOnlineStatus } from "../Helpers/useOnlineStatus";
import BlockUsers from "../Components/ProfileComponents/BlockUsers";
import messagingPermissions from "../Helpers/RequestMessagingPermision";

import { isSimpleAndBlockedConversation } from "../Helpers/chatHelper/ConversationHelper";
import RequestNotificationsModal from "../Components/GenericComponents/RequestNotificationsModal";
import { useCookies } from "react-cookie";
import SubscriptionModal from "../Components/ProfileComponents/SubscriptionModal";
import { useSearchParams } from "react-router-dom";
import DeleteReasonsModal from "../Components/ProfileComponents/DeleteReasonsModal";
import MessageDetailsModal from "../Components/Chat/MessageDetailsModal";

const ChatPage = ({ setLoading }) => {
  const {
    user,
    userCustomerInfo,
    updateUser,
    translationsLanguages,
    getTranslationsLanguages,
    updateUserWithNewPushToken,
    setPushToken,
    getStripePaymentLink,
    cancelStripeSubscription,
    checkSubscriptions,
  } = useAuthService();

  const {
    add_observer,
    remove_observer,
    sendCloseCallMessage,
    sendDenyTranslationMessage,
    connectHandler,
  } = useWebSocketDataService();

  const {
    getConversationsForUser,
    getMessageForConversation,
    addMessageForConversation,
    markMessageAsRead,
    editMessageForConversation,
    reactToMessageForConversation,
    deleteMessageForConversation,
    add_rating,
    getContacts,
    conversationsWithPhone,
    replyMessage,
    createQuickChat,
    closeQuickChat,
    blockContact,
    unblockContact,
  } = useConversationService();

  const { calls, callsRef, getCallDetails, getCalls, createCall } =
    useCallsService();

  const isOnline = useOnlineStatus();
  const [searchParams, setSearchParams] = useSearchParams();

  var initialInputvalues = {
    name: user.name,
    about: user.about || "",
    cultural_background: user.cultural_background,
  };

  const branch = require("branch-sdk");
  const [cookies, setCookie] = useCookies(["notificationsModal"]);

  const [conversations, setConversations] = useState();

  const [selectedConversation, setSelectedConversation] = useState();
  const [currentConversationMessages, setCurrentConversationMessages] =
    useState([]);

  const messagePage = useRef(1);

  const currentConversationRef = useRef(null);
  const currentMessagesRef = useRef(null);
  const conversationsRef = useRef(null);
  const messageInputRef = useRef();

  const shouldScrollBottom = useRef(true);

  const shouldLoadMoreMessages = useRef(true);
  const mediaInputFileRef = useRef();
  const fileInputRef = useRef();

  const qcLinkRef = useRef(null);

  const [searchInput, setSearchInput] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [mediaInput, setMediaInput] = useState(null);
  const [fileInput, setFileInput] = useState(null);

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isAddNewChatGroupOpen, setIsAddNewChatGroupOpen] = useState(false);
  const [isAddNewChatOpen, setIsAddNewChatOpen] = useState(false);
  const [isAddNewContactOpen, setIsAddNewContactOpen] = useState(false);
  const [isAddQuickChatOpen, setIsAddQuickChatOpen] = useState(false);
  const [isQcModalOpen, setIsQcModalOpen] = useState(false);

  const [infoChanged, setInfoChanged] = useState(false);

  const [inputValues, setInputValues] = useState(initialInputvalues);
  const [birthdate, setBirthdate] = useState(user.birthdate);
  const [language, setLanguage] = useState(user.language);
  const [gender, setGender] = useState(user.gender);

  const [showFullScreenImg, setShowFullScreenImg] = useState(false);
  const [fullScreenImg, setFullScreenImg] = useState(null);

  const [isContactInvited, setIsContactInvited] = useState(false);
  const [selectedMessageForContextMenu, setSelectedMessageForContextMenu] =
    useState();
  const scrollHeightRef = useRef(0);

  const [showGroupChatDetails, setShowGroupChatDetails] = useState(false);
  const [showRatingCard, setShowRatingCard] = useState(false);
  const [showEditMessageModal, setShowEditMessageModal] = useState(false);
  const [showReplyMessageModal, setShowReplyMessageModal] = useState(false);

  const [showQuickChatDetails, setShowQuickChatDetails] = useState(false);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [toggle, setToggle] = useState(MESSAGES_LIST);

  const [contacts, setContacts] = useState([]);

  const [selectedCall, setSelectedCall] = useState(null);
  const [selectedCallAccess, setSelectedCallAccess] = useState(null);
  const [incomingCallDetails, setIncomingCallDetails] = useState(null);

  const [isBlockUsersOpen, setIsBlockUsersOpen] = useState(false);

  const activeCallRef = useRef(null);
  const incomingCallRef = useRef(null);

  const [showForwardModal, setShowForwardModal] = useState(false);
  const [showBlockedChatModal, setShowBlockedChatModal] = useState(false);
  const [showRequestNotificationModal, setShowRequestNotificationModal] =
    useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [deleteReasonModalOpen, setIsDeleteReasonModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [showMessageDetailsModal, setShowMessageDetailModal] = useState(false);
  const [messageMood, setMessageMood] = useState(NEUTRAL_MOOD)

  const navigate = useNavigate();
  const MENU_ID = "menu-id";

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function displayRightClickMenu(e, messageId, conversationId, messageObject) {
    setShowEditMessageModal(false);
    setShowForwardModal(false);
    setShowReplyMessageModal(false);
    setShowMessageDetailModal(false)

    setSelectedMessageForContextMenu({
      messageId: messageId,
      conversationId: conversationId,
      messageObject: messageObject,
    });

    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }

  const checkIfShouldScroll = () => {
    let element = document.getElementById("messagesList");

    let scrollheight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    let x = scrollheight - scrollTop - clientHeight;

    if (parseFloat(x) < 1) {
      return true;
    } else {
      return false;
    }
  };

  const MessagesChannelObserver = new WebSocketObserver(
    "MessagesChannel",
    (message) => {

      const response = JSON.parse(message);
      if (response) {
        if (response.conversation_id === currentConversationRef?.current?.id) {
          shouldScrollBottom.current = checkIfShouldScroll();
          if (response.reactions.length > 0) {
            shouldScrollBottom.current = false;
          }
          const newMessages = handleReceivedMessages(
            currentMessagesRef.current,
            response
          );
          const sorted = sortReceivedMessages(newMessages);
          currentMessagesRef.current = sorted;
          setCurrentConversationMessages(sorted);
        } else {
          let existingConversation = conversationsRef.current.find(
            (conv) => conv.id === response.conversation_id
          );
          if (existingConversation) {
            handleConversationInfoChanged(
              existingConversation.id,
              "last_message",
              response
            );
          } else {
            loadConversations();
          }
        }
      }
    }
  );

  const CallChannelObserver = new WebSocketObserver(
    "CallChannel",
    (message) => {
      handleCallWsResponse(message);
    }
  );

  const handleCallWsResponse = (response) => {

    if (response.update === "close") {

      if (response.call_uuid === activeCallRef.current?.details.uuid) {
        setSelectedCall(null);
        setSelectedCallAccess(null);
        setIncomingCallDetails(null);
        callingSoundHowl.stop();
        activeCallRef.current = null;
      }

      if (response.call_uuid === incomingCallRef.current?.details.uuid) {
        // console.log("3")
        setCallModalOpen(false);
        callingSoundHowl.stop();
      }
    }

    if (response.update === "open") {
      if (activeCallRef.current) {
        sendCloseCallMessage(response.call_uuid);
        incomingCallRef.current = null;
      } else {
        handleGetIncomingCallDetails(response.call_uuid);
        handleOpenCallModal();
      }
    }

    if (response.update == "request_translations") {
      sendDenyTranslationMessage(response.call_uuid);
    }
  };

  const handleMessageInputChange = (e) => {
    const { value } = e.currentTarget;
    setMessageInput(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const initializeInputValue = () => {
    initialInputvalues = {
      name: user.name,
      about: user.about || "",
      cultural_background: user.cultural_background,
    };

    setInputValues(initialInputvalues);
    setGender(user.gender);
    setLanguage(user.language);
    setBirthdate(user.birthdate);
  };

  const handleUpdateUser = () => {
    const initial = moment(user.birthdate);
    const second = moment(birthdate);
    let comparedDates = initial.startOf("day").isSame(second.startOf("day"));
    var objectData = {};
    if (user.country_code) {
      objectData.country_code = user.country_code;
    }
    if (inputValues.about !== initialInputvalues.about) {
      objectData.about = inputValues.about;
    }

    if (inputValues.name !== initialInputvalues.name) {
      objectData.name = inputValues.name;
    }

    if (
      inputValues.cultural_background !== initialInputvalues.cultural_background
    ) {
      objectData.cultural_background = inputValues.cultural_background;
    }
    if (language !== user.language) {
      objectData.language = language;
    }
    if (gender !== user.gender) {
      objectData.gender = gender;
    }
    if (!comparedDates) {
      objectData.birthdate = moment(birthdate).format("yyyy-MM-DD");
    }

    updateUser(
      objectData,
      function (response) {
        notifySuccess(`${localizationStrings.profile.profile_updated}`);
        setInfoChanged(false);
        setIsProfileOpen(false);
      },
      function (error) {
        console.log(error, "error");
        notifyError(`${localizationStrings.profile.something_wrong}`);
      }
    );
  };

  const loadConversations = (onSuccess) => {
    setLoading(true)
    getConversationsForUser(
      function (response) {
        let conversations = response.data.conversations;
        let sortedConversation = sortNewestConversations(conversations);
        conversationsRef.current = sortedConversation;
        setConversations(sortedConversation);
        onSuccess && onSuccess(response);
        setLoading(false)
      },
      function (error) {
        setLoading(false)
        console.log(error);
        notifyError(`${localizationStrings.profile.something_wrong}`);
      }
    );
  };

  const loadMessageForConversation = (conversation, page) => {
    getMessageForConversation(
      conversation.id,
      page,
      function (response) {
        let responseMessages = response.data.messages;
        shouldLoadMoreMessages.current = !(response.data.messages.length < 25);
        let filteredMessages;
        if (page !== 1) {
          shouldScrollBottom.current = false;
          filteredMessages = handleReceivedMessages(
            currentConversationMessages,
            responseMessages
          );
        } else {
          filteredMessages = handleReceivedMessages([], responseMessages);
        }
        const sortedMessages = sortReceivedMessages(filteredMessages);
        currentMessagesRef.current = sortedMessages;
        setCurrentConversationMessages(sortedMessages);
      },
      function (error) {
        console.log(error, "error getMessageForConversation");
      }
    );
  };

  const initializeConversationMood = (messages) => {

    let currentUsermessages = messages.filter((message) => message.user_id === user.id)
    let lastMessageMood = last(currentUsermessages)?.mood

    if (lastMessageMood) {
      setMessageMood(lastMessageMood)
    } else {
      setMessageMood(NEUTRAL_MOOD)
    }

  }

  const handleStorageRatingCounter = () => {
    let currentCounter = LocalStorage.getRatingCounter();
    if (!currentCounter) {
      LocalStorage.setRatingCounter(1);
    } else {
      LocalStorage.setRatingCounter(parseInt(currentCounter) + 1);
    }
  };
  const handleSelectConversation = (conversation) => {


    shouldScrollBottom.current = true;
    messagePage.current = 1;
    currentConversationRef.current = conversation;
    setSelectedConversation(conversation);

    if (isContactInvited) {
      setIsContactInvited(false);
    }
    if (mediaInput) {
      setMediaInput(null);
      mediaInputFileRef.current.value = null;
    }
    if (fileInput) {
      setFileInput(null);
      fileInputRef.current.value = null;
    }
    if (showEditMessageModal) {
      setShowEditMessageModal(false);
    }

    if (isSimpleAndBlockedConversation(conversation)) {
      setShowBlockedChatModal(true);
    } else {
      setShowBlockedChatModal(false);
    }

    setShowGroupChatDetails(false);
    setShowQuickChatDetails(false);
    setShowMessageDetailModal(false);
    handleStorageRatingCounter();
    handleCloseReplyMessageOption();
    setMessageInput("");
    setSelectedCall(null);
    setSelectedCallAccess(null);

  };

  const handleSelectCall = async (call) => {
    if (Object.keys(userCustomerInfo?.entitlements.active).length == 0) {
      setIsSubscriptionModalOpen(true);
    }
    else {
      checkMicrophonePermission().then((permission) => {
        // console.log(permission, "permission")
        if (permission === "granted") {
          let otherParticipant = call?.other_participants[0];
          let otherParticipantId = otherParticipant.id;
          if (!otherParticipant.blocked) {
            handleSelectConversation(null);
            setSelectedCall(call);
            handleCreateCall(otherParticipantId);
          } else {
            notifyError("This contact is blocked");
          }

        } else if (permission === "denied") {
          notifyError("To enable calling, please allow microphone access for this app in your browser’s settings.")
        }
      })

    }
  };

  const handleSendMedia = (type, file, text, thumbnail) => {
    setLoading(true);
    let dataObject = new FormData();
    let nowDate = moment().toISOString();

    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", "");
    dataObject.append("mood", messageMood);

    if (type === "image") {
      dataObject.append("image", file);
    }

    if (type === "video") {
      dataObject.append("video", file);
      dataObject.append("video_thumbnail", thumbnail);
    }

    if (text.length > 0) {
      dataObject.append("original_message", text);
    }

    addMessageForConversation(
      dataObject,
      selectedConversation.id,
      function (response) {
        const sortedMessages = sortReceivedMessages(
          handleReceivedMessages(
            currentMessagesRef.current,
            response.data.message
          )
        );
        currentMessagesRef.current = sortedMessages;
        setCurrentConversationMessages(sortedMessages);
        mediaInputFileRef.current.value = "";
        fileInputRef.current.value = "";
        setMessageInput("");
        shouldScrollBottom.current = true;
        setLoading(false);
      },
      function (error) {
        mediaInputFileRef.current.value = "";
        fileInputRef.current.value = "";
        console.log(error, "error");
        setLoading(false);
        if (error.response.status === 413) {
          notifyError(
            `File size exceeds the limit. Please ensure the file is less than ${handleConvertBitesToMb(
              error.response.data.max_size
            )}MB`
          );
        } else {
          notifyError("Something wrong");
        }
      }
    );
  };

  const handleSendFile = (file, text) => {
    setLoading(true);
    let dataObject = new FormData();
    let nowDate = moment().toISOString();

    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", "");
    dataObject.append("document", file);
    dataObject.append("mood", messageMood);

    if (text.length > 0) {
      dataObject.append("original_message", text);
    }

    addMessageForConversation(
      dataObject,
      selectedConversation.id,
      function (response) {
        const sortedMessages = sortReceivedMessages(
          handleReceivedMessages(
            currentMessagesRef.current,
            response.data.message
          )
        );
        currentMessagesRef.current = sortedMessages;
        setCurrentConversationMessages(sortedMessages);
        mediaInputFileRef.current.value = "";
        fileInputRef.current.value = "";
        setMessageInput("");

        shouldScrollBottom.current = true;

        setLoading(false);
      },
      function (error) {
        mediaInputFileRef.current.value = "";
        fileInputRef.current.value = "";
        console.log(error, "error");
        setLoading(false);
        if (error.response.status === 413) {
          notifyError(
            `File size exceeds the limit. Please ensure the file is less than ${handleConvertBitesToMb(
              error.response.data.max_size
            )}MB`
          );
        } else {
          notifyError("Something wrong");
        }
        if (error.response.status === 413) {
          notifyError(
            `File size exceeds the limit. Please ensure the file is less than ${handleConvertBitesToMb(
              error.response.data.max_size
            )}MB`
          );
        } else {
          notifyError("Something wrong");
        }
      }
    );
  };

  const handleSendMessage = () => {
    if (!messageInput.replace(/\s/g, "").length) {
      return;
    }

    let nowDate = moment().toISOString();
    let dataObject = new FormData();
    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", messageInput);
    dataObject.append("mood", messageMood);

    addMessageForConversation(
      dataObject,
      selectedConversation.id,
      function (response) {
        const newMessages = handleReceivedMessages(
          currentMessagesRef.current,
          response.data.message
        );
        const sorted = sortReceivedMessages(newMessages);
        currentMessagesRef.current = sorted;
        setCurrentConversationMessages(sorted);
        //handleNewMessageReceived(response.data.message);
        setMessageInput("");
        shouldScrollBottom.current = true;
      },
      function (error) {
        console.log(error, "error");
      }
    );
  };

  const handleReplyMessage = () => {
    if (!messageInput.replace(/\s/g, "").length) {
      return;
    }

    let nowDate = moment().toISOString();
    let dataObject = new FormData();
    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", messageInput);
    dataObject.append("mood", messageMood);

    replyMessage(
      dataObject,
      selectedMessageForContextMenu.conversationId,
      selectedMessageForContextMenu.messageId,
      (response) => {
        // console.log(response, "response");
        const newMessages = handleReceivedMessages(
          currentMessagesRef.current,
          response.data.message
        );
        const sorted = sortReceivedMessages(newMessages);
        currentMessagesRef.current = sorted;
        setCurrentConversationMessages(sorted);
        //handleNewMessageReceived(response.data.message);
        setMessageInput("");
        shouldScrollBottom.current = true;
        handleCloseReplyMessageOption();
      },
      (error) => {
        notifyError("Something wrong");
      }
    );
  };

  const handleReplyWithMedia = (type, file, text, thumbnail) => {
    setLoading(true);
    let dataObject = new FormData();
    let nowDate = moment().toISOString();

    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", "");
    dataObject.append("mood", messageMood);

    if (text.length > 0) {
      dataObject.append("original_message", text);
    }
    if (type === "image") {
      dataObject.append("image", file);
    }

    if (type === "video") {
      dataObject.append("video", file);
      dataObject.append("video_thumbnail", thumbnail);
    }

    replyMessage(
      dataObject,
      selectedMessageForContextMenu.conversationId,
      selectedMessageForContextMenu.messageId,
      function (response) {
        const sortedMessages = sortReceivedMessages(
          handleReceivedMessages(
            currentMessagesRef.current,
            response.data.message
          )
        );
        currentMessagesRef.current = sortedMessages;
        setCurrentConversationMessages(sortedMessages);
        mediaInputFileRef.current.value = "";
        fileInputRef.current.value = "";
        setLoading(false);
        setMessageInput("");
        shouldScrollBottom.current = true;
        handleCloseReplyMessageOption();
      },
      function (error) {
        mediaInputFileRef.current.value = "";
        fileInputRef.current.value = "";
        console.log(error, "error");
        setLoading(false);
        if (error.response.status === 413) {
          notifyError(
            `File size exceeds the limit. Please ensure the file is less than ${handleConvertBitesToMb(
              error.response.data.max_size
            )}MB`
          );
        } else {
          notifyError("Something wrong");
        }
        if (error.response.status === 413) {
          notifyError(
            `File size exceeds the limit. Please ensure the file is less than ${handleConvertBitesToMb(
              error.response.data.max_size
            )}MB`
          );
        } else {
          notifyError("Something wrong");
        }
      }
    );
  };

  const handleReplyWithFile = (file, text) => {
    setLoading(true);
    let dataObject = new FormData();
    let nowDate = moment().toISOString();

    dataObject.append("device_sent_date", nowDate);
    dataObject.append("original_message", "");
    dataObject.append("document", file);
    dataObject.append("mood", messageMood);

    replyMessage(
      dataObject,
      selectedMessageForContextMenu.conversationId,
      selectedMessageForContextMenu.messageId,
      function (response) {
        const sortedMessages = sortReceivedMessages(
          handleReceivedMessages(
            currentMessagesRef.current,
            response.data.message
          )
        );
        currentMessagesRef.current = sortedMessages;
        setCurrentConversationMessages(sortedMessages);
        mediaInputFileRef.current.value = "";
        fileInputRef.current.value = "";
        setLoading(false);
        setMessageInput("");
        shouldScrollBottom.current = true;
        handleCloseReplyMessageOption();
      },
      function (error) {
        mediaInputFileRef.current.value = "";
        fileInputRef.current.value = "";
        console.log(error, "error");
        setLoading(false);
        if (error.response.status === 413) {
          notifyError(
            `File size exceeds the limit. Please ensure the file is less than ${handleConvertBitesToMb(
              error.response.data.max_size
            )}MB`
          );
        } else {
          notifyError("Something wrong");
        }
        if (error.response.status === 413) {
          notifyError(
            `File size exceeds the limit. Please ensure the file is less than ${handleConvertBitesToMb(
              error.response.data.max_size
            )}MB`
          );
        } else {
          notifyError("Something wrong");
        }
      }
    );
  };

  const handleEditMessage = () => {
    editMessageForConversation(
      selectedMessageForContextMenu.conversationId,
      selectedMessageForContextMenu.messageId,
      messageInput,
      (response) => {
        handleEditMessageResponse(response.data.message);

        handleCloseEdit();
      },
      (error) => {
        notifyError("Something wrong");
      }
    );
  };

  const handleEditMessageResponse = (message) => {
    let messages = currentConversationMessages;
    messages = messages.map((item) => {
      if (item.id === message.id) {
        return message;
      } else {
        return item;
      }
    });
    setCurrentConversationMessages(messages);
  };

  const handleSendButton = () => {
    if (showEditMessageModal) {
      handleEditMessage();
      return;
    }

    if (showReplyMessageModal) {
      handleReplyMessage();
      return;
    }

    handleSendMessage();
  };

  const checkProfileInfoChanges = () => {
    const initial = moment(user.birthdate);
    const second = moment(birthdate);
    let comparedDates = true;

    if (initial.isValid() || second.isValid()) {
      comparedDates = initial.startOf("day").isSame(second.startOf("day"));
    }
    if (
      inputValues.about !== initialInputvalues.about ||
      inputValues.cultural_background !==
      initialInputvalues.cultural_background ||
      inputValues.name !== initialInputvalues.name ||
      language !== user.language ||
      gender !== user.gender ||
      !comparedDates
    ) {
      setInfoChanged(true);
    } else {
      setInfoChanged(false);
    }
  };

  const handleGetMoreMessages = () => {
    if (shouldLoadMoreMessages.current) {
      messagePage.current += 1;
      loadMessageForConversation(selectedConversation, messagePage.current);
    }
  };

  const handleConversationInfoChanged = (id, field, value) => {
    setConversations((prevData) => {
      var newData = prevData?.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      });
      let sortedNewData = sortNewestConversations(newData);
      return sortedNewData;
    });
  };

  const markMessagesRead = (messages) => {

    let newMessages = messages.map((message) => {
      if (message.user_id !== user.id) {

        let translationForCurrentUser = findTranslatedTextForUser(message.message_translations, user)

        if (translationForCurrentUser.read_at === null) {
          markMessageAsRead(
            message.conversation_id,
            message.id,
            function (response) {
              //const newMessage = response.data.message
              // handleMessageInfoChange(newMessage.id, "read", newMessage.read)
            },
            function (error) {
              console.log(error, "error mark as read");
            }
          );
        }
      }
    });
    return newMessages;
  };

  const setLastMessage = (currentMessages) => {
    const lastMessage = currentMessages[currentMessages.length - 1];
    handleConversationInfoChanged(
      lastMessage.conversation_id,
      "last_message",
      lastMessage
    );
  };

  const handleReactToMessage = (reactionText, conversationId, messageId) => {
    let reaction = mapEmoji(reactionText);
    if (!reaction) {
      return;
    }
    reactToMessageForConversation(
      reaction,
      conversationId,
      messageId,
      function (response) { },
      function (error) {
        console.log(error);
      }
    );
  };

  const handleShowFullScreenImg = (image, messageId, conversationId) => {
    setFullScreenImg({
      image: image,
      messageId: messageId,
      conversationId: conversationId,
    });
    setShowFullScreenImg(true);
  };

  const handleCloseFullScreenImg = () => {
    setShowFullScreenImg(false);
    setFullScreenImg(null);
  };

  const handleDeleteMessage = (conversationId, messageId) => {
    deleteMessageForConversation(
      conversationId,
      messageId,
      function (response) { },
      function (error) {
        console.log(error);
        notifyError(`${localizationStrings.profile.something_wrong}`);
      }
    );
  };

  const handleDeletePhoto = () => {
    handleDeleteMessage(fullScreenImg.conversationId, fullScreenImg.messageId);
    setShowFullScreenImg(false);
  };

  const handleReportMessage = () => {
    window.location = "mailto:support@emotii.ai";
  };

  const handleShowGroupChatDetails = () => {
    setShowGroupChatDetails(!showGroupChatDetails);
  };

  const handleShowConversationDetailsMenu = (selectedConversation) => {
    if (selectedConversation.quick_chat) {
      handleShowQcChatDetails();
    } else {
      handleShowGroupChatDetails();
    }

    if (showMessageDetailsModal) {
      setShowMessageDetailModal(false)
    }

  };

  const handleShowQcChatDetails = () => {
    setShowQuickChatDetails(!showQuickChatDetails);
  };

  const handleForwardMessage = () => {
    setShowForwardModal(true);
  };

  const handleSendRating = (rating) => {
    if (!selectedConversation) {
      setShowRatingCard(false);
    }
    add_rating(
      selectedConversation.id,
      rating,
      function (response) {
        notifySuccess(`${localizationStrings.chat.rating_sent}`);
        setShowRatingCard(false);
      },
      function (error) {
        notifyError(`${localizationStrings.profile.something_wrong}`);
        setShowRatingCard(false);
        console.log(error);
      }
    );
  };

  const loadContacts = () => {
    getContacts(
      function (response) {
        let sortedAppContacts = response.data["on_app_contacts"].sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        let sortedOutsideAppContacts = response.data["outside_contacts"].sort(
          (a, b) => (a.name > b.name ? 1 : -1)
        );
        setContacts({
          on_app_contacts: sortedAppContacts,
          outside_contacts: sortedOutsideAppContacts,
        });
      },
      function (error) {
        console.log(error);
      }
    );
  };

  const handleStartNewChat = (contact) => {
    let phone = contact.phone_number;
    if (phone === user.phone_number) {
      notifyError(`${localizationStrings.new_chat.cant_have_conv_with_u}`);
    } else {
      conversationsWithPhone(
        phone,
        function (response) {
          let clone = cloneDeep(conversations);
          let found = clone.find(
            (item) => item.id === response.data.conversation.id
          );
          if (found) {
            handleSelectConversation(found);
            setIsAddNewChatOpen(false);
          } else {
            let newConvList = [response.data.conversation].concat(clone);
            currentConversationRef.current = newConvList;
            setConversations(newConvList);
            handleSelectConversation(response.data.conversation);
            setIsAddNewChatOpen(false);
          }
        },
        function (error) {
          console.log(error, "error");
          notifyError(`${localizationStrings.profile.something_wrong}`);
        }
      );
    }
  };

  const handleInviteContact = (contact) => {
    createBranchLink();
  };

  const createBranchLink = (genericLink) => {
    let title = "Join emotii";
    let message = `${user.name} has invited you to join emotii. Tap the link below and start communicating without boundaries.`;
    let textMessage =
      "Check out this new app, tap the link, and let's communicate without boundaries.";
    let generatedLink = "";

    var linkData = {
      customData: true,
      data: {
        invited_by: user.id,
      },
    };

    branch.link(linkData, (err, data) => {
      generatedLink = data;
      if (genericLink) {
        copy(`${title}\n${textMessage}\n${generatedLink} `);
      } else {
        copy(`${title}\n${message}\n${generatedLink} `);
      }
      notifySuccess("Link copied");
    });
  };

  const handleShareQcLink = (link) => {
    copy(link);
    notifySuccess("Link copied");
  };

  const handleEditMessageOption = () => {
    setShowEditMessageModal(true);
    setMessageInput(
      selectedMessageForContextMenu.messageObject.original_message
    );
    messageInputRef.current.focus();
  };

  const handleCloseEdit = () => {
    setShowEditMessageModal(false);
    setSelectedMessageForContextMenu(null);
    setMessageInput("");
  };

  const handleReplyMessageOption = () => {
    setShowReplyMessageModal(true);
    messageInputRef.current.focus();
  };

  const handleCloseReplyMessageOption = () => {
    setShowReplyMessageModal(false);
    setSelectedMessageForContextMenu(null);
    setMessageInput("");
  };

  const handleCloseForwardModal = () => {
    setShowForwardModal(false);
  };

  const handleSendMediaButton = (type, file, text, thumbnail) => {
    if (showReplyMessageModal) {
      handleReplyWithMedia(type, file, text, thumbnail);
    } else {
      handleSendMedia(type, file, text, thumbnail);
    }
  };
  const handleSendFileButton = (file, text) => {
    if (showReplyMessageModal) {
      handleReplyWithFile(file, text);
    } else {
      handleSendFile(file, text);
    }
  };

  const handleCopyOption = (message) => {
    copy(message.original_message);
    notifySuccess("Copy");
  };

  const handleCreateQuickChat = (name) => {
    setLoading(true);
    createQuickChat(
      name,
      (response) => {
        let clone = cloneDeep(conversations);
        let found = clone.find(
          (item) => item.id === response.data.conversation.id
        );
        if (found) {
          handleSelectConversation(found);
          setIsAddQuickChatOpen(false);
        } else {
          let newConvList = [response.data.conversation].concat(clone);
          currentConversationRef.current = newConvList;

          setConversations(newConvList);
          handleSelectConversation(response.data.conversation);
          setIsAddQuickChatOpen(false);
          qcLinkRef.current = response.data.conversation.url;
          setIsQcModalOpen(true);
        }

        setLoading(false);
      },
      (error) => {
        console.log(error);
        notifyError("Something wrong");
        setLoading(false);
      }
    );
  };

  const handleCloseQcChat = (chatId) => {
    setLoading(true);
    closeQuickChat(
      chatId,
      (response) => {
        setLoading(false);
        loadConversations();
        setSelectedConversation(null);
      },
      (error) => {
        notifyError("Something wrong");
        console.log(error);
        setLoading(false);
      }
    );
  };

  const handleCloseChatBtn = (chatId) => {
    handleCloseQcChat(chatId);
    setShowQuickChatDetails(false);
  };

  const loadCalls = () => {
    getCalls(
      (response) => { },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const checkMicrophonePermission = async () => {
    try {
      if (!navigator.permissions) {
        throw new Error('Permissions API not supported');
      }
      const result = await navigator.permissions.query({ name: 'microphone' });

      if (result.state == "prompt") {
        return requestMicrophonePermission()
      } else {
        return result.state
      }

    } catch (err) {
      console.log(err)
      return
    }
  };

  const requestMicrophonePermission = async () => {

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // You can do something with the stream if needed
      // console.log('Microphone stream:', stream);
      return 'granted';
    } catch (err) {

      console.log(err.message);
      return 'denied';
    }
  };




  const handleCreateCall = (userId) => {

    createCall(
      userId,
      (response) => {
        loadCalls();
        setSelectedCall(response.data.details);
        setSelectedCallAccess(response.data.access);
        activeCallRef.current = response.data;
        callingSoundHowl.play();
      },

      (error) => {
        notifyError("Something wrong....");
        console.log(error);
      }
    );


  };

  const handleCreateCallHeaderClick = (call) => {
    handleSelectCall(call);
    setToggle(CALLS_LIST);
  };

  const handleCreateCallOnContactClick = (contact) => {
    if (Object.keys(userCustomerInfo?.entitlements.active).length == 0) {
      setIsSubscriptionModalOpen(true);
    } else {
      setToggle(CALLS_LIST);
      let otherParticipant = contact;
      let otherParticipantId = contact.id;
      if (!otherParticipant.blocked) {
        handleSelectConversation(null);
        handleCreateCall(otherParticipantId);
      } else {
        notifyError("This contact is blocked");
      }
    }
  };

  const handleGetIncomingCallDetails = (uuid) => {
    getCallDetails(
      uuid,
      (response) => {
        setIncomingCallDetails(response.data);
        incomingCallRef.current = response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleCloseCallModal = () => {
    sendCloseCallMessage(incomingCallRef.current.details.uuid);
    activeCallRef.current = null;
    incomingCallRef.current = null;

    setCallModalOpen(false);
  };

  const handleOpenCallModal = () => {
    setCallModalOpen(true);
  };

  const handleAnswerIncomingCall = () => {
    setSelectedCall(incomingCallDetails.details);
    setSelectedCallAccess(incomingCallDetails.access);
    activeCallRef.current = incomingCallDetails;
    incomingCallRef.current = null;
    setIncomingCallDetails(null);
    setCallModalOpen(false);
  };

  const resetCall = () => {
    setSelectedCall(null);
    setSelectedCallAccess(null);
    incomingCallRef.current = null;
    activeCallRef.current = null;
  };

  const handleCloseBlockUsers = () => {
    setIsBlockUsersOpen(false);
  };
  const handleOpenBlockUsers = () => {
    setIsBlockUsersOpen(true);
  };

  const handleBlockContact = (contact, callback) => {
    setLoading(true);
    blockContact(
      contact.phone_number,
      contact.name,
      (response) => {

        loadConversations((resp) => {
          if (!selectedConversation) {
            return
          }
          let found = resp.data.conversations.find(
            (item) => item.id === selectedConversation.id
          );

          setSelectedConversation(found);
        });
        callback();
        // setLoading(false)
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const handleUnblockContact = (contact, callback) => {
    setLoading(true);
    unblockContact(
      contact.phone_number,
      (response) => {
        loadConversations((resp) => {
          if (!selectedConversation) {
            return
          }
          let found = resp.data.conversations.find(
            (item) => item.id === selectedConversation.id
          );

          setSelectedConversation(found);
          setShowBlockedChatModal(false);
        });
        callback();
        // setLoading(false)
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };
  const handleCloseRequestNotificationModal = () => {
    setShowRequestNotificationModal(false);
    setCookie("notificationsModal", "disabled");
  };
  const handleRequestNotificationsPermission = () => {
    messagingPermissions.requestPermission((response) => {
      setCookie("notificationsModal", "disabled");
      setPushToken(response);
      updateUserWithNewPushToken(response);
    });
    setShowRequestNotificationModal(false);
  };

  const fetchPushTokenIfPossible = () => {
    messagingPermissions.getCurrentNotificationTokenIfPossible((response) => {
      setPushToken(response);
      updateUserWithNewPushToken(response);
    });
  };

  const handleOpenSubscriptionModal = () => {
    setIsSubscriptionModalOpen(true);
  };

  const handleCloseSubscriptionModal = () => {
    setIsSubscriptionModalOpen(false);
  };

  const handleSubscribeClick = () => {
    setLoading(true);
    getStripePaymentLink(
      (response) => {
        const link = response.data.url;
        window.location = link;
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const handleUnSubscribeClick = () => {
    setLoading(true);
    cancelStripeSubscription(
      (response) => {

        setIsSubscriptionModalOpen(false);

        notifySuccess("Subscription cancelled");
        setTimeout(() => {
          checkSubscriptions();
          setLoading(false);
        }, 3000);
      },
      (error) => {
        console.log(error);
        notifyError("Something wrong");
      }
    );
  };

  const closeDeleteReasonModal = () => {
    setIsDeleteReasonModalOpen(false);
    setSelectedReasons([]);
  };

  const handleShowMessageInfoModal = () => {
    setShowMessageDetailModal(!showMessageDetailsModal)
  }

  const handleOpenMessageInfoModal = () => {
    setShowGroupChatDetails(false);
    setShowQuickChatDetails(false)
    setShowMessageDetailModal(true)
  }

  const handleBranchDeepLink = () => {
    branch.data((error, data) => {
      const parseData = data.data_parsed
      let keys = Object.keys(parseData)
      if (keys.includes("$deeplink_path") && parseData["$deeplink_path"] == "subscription") {
        setIsSubscriptionModalOpen(true)
      }
    })
  }

  useEffect(() => {
    loadConversations();
    loadCalls();
    loadContacts();
    add_observer(MessagesChannelObserver);
    add_observer(CallChannelObserver);
    handleBranchDeepLink()
    return () => {
      remove_observer(MessagesChannelObserver);
      remove_observer(CallChannelObserver);
    };

  }, []);

  useEffect(() => {
    initializeInputValue();
  }, [user]);

  useEffect(() => {
    if (!selectedConversation || selectedConversation.uuid) {
      return;
    }
    loadMessageForConversation(selectedConversation, 1);
  }, [selectedConversation]);

  useEffect(() => {
    if (currentConversationMessages.length !== 0) {
      setLastMessage(currentConversationMessages);
    }

    if (!currentMessagesRef.current) {
      return;
    }
    markMessagesRead(currentMessagesRef.current);
    initializeConversationMood(currentMessagesRef.current)
  }, [currentConversationMessages]);

  useEffect(() => {
    checkProfileInfoChanges();
  }, [inputValues, birthdate, language, gender]);

  useEffect(() => {
    if (translationsLanguages.length != 0) {
      return;
    }
    getTranslationsLanguages(
      function (response) { },
      function (error) { }
    );
  }, [isProfileOpen]);

  useEffect(() => {
    if (
      parseInt(LocalStorage.getRatingCounter()) % 20 === 0 &&
      selectedConversation
    ) {
      setShowRatingCard(true);
    }
  }, [parseInt(LocalStorage.getRatingCounter())]);

  useEffect(() => {
    if (isMobile) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("from_stripe")) {
      setSearchParams((prev) => {
        prev.delete("from_stripe");
        return prev;
      });
    }

    if (!("Notification" in window)) {
      return;
    }

    if (Notification.permission === "default" && !cookies.notificationsModal) {
      setShowRequestNotificationModal(true);
    }

    fetchPushTokenIfPossible();
  }, []);

  return (
    <FirstWrapper>
      {showRequestNotificationModal && (
        <RequestNotificationsModal
          handleRequestNotificationsPermission={
            handleRequestNotificationsPermission
          }
          handleClose={handleCloseRequestNotificationModal}
        />
      )}
      <Container>
        <Left>
          <UserMenu
            setIsProfileOpen={setIsProfileOpen}
            setIsAddNewChatGroupOpen={setIsAddNewChatGroupOpen}
            setIsAddNewChatOpen={setIsAddNewChatOpen}
            setIsAddQuickChatOpen={setIsAddQuickChatOpen}
          />

          {isProfileOpen && (
            <ProfileOptions
              inputValues={inputValues}
              birthdate={birthdate}
              setBirthdate={setBirthdate}
              gender={gender}
              setGender={setGender}
              language={language}
              setLanguage={setLanguage}
              infoChanged={infoChanged}
              setInfoChanged={setInfoChanged}
              handleInputChange={handleInputChange}
              setIsProfileOpen={setIsProfileOpen}
              handleUpdateUser={handleUpdateUser}
              initializeInputValue={initializeInputValue}
              createBranchLink={createBranchLink}
              handleOpenBlockUsers={handleOpenBlockUsers}
              handleOpenSubscriptionModal={handleOpenSubscriptionModal}
              deleteConfirmationOpen={deleteConfirmationOpen}
              setDeleteConfirmationOpen={setDeleteConfirmationOpen}
              setIsDeleteReasonModalOpen={setIsDeleteReasonModalOpen}
              selectedReasons={selectedReasons}
            ></ProfileOptions>
          )}

          {isAddNewChatGroupOpen && (
            <AddNewGroup
              setIsAddNewChatGroupOpen={setIsAddNewChatGroupOpen}
              conversations={conversations}
              setConversations={setConversations}
              currentConversationRef={currentConversationRef}
              handleSelectConversation={handleSelectConversation}
              contacts={contacts}
              setIsAddNewContactOpen={setIsAddNewContactOpen}
              handleInviteContact={handleInviteContact}
            />
          )}
          {isBlockUsersOpen && (
            <BlockUsers
              handleClose={handleCloseBlockUsers}
              setLoading={setLoading}
              handleBlockContact={handleBlockContact}
              handleUnblockContact={handleUnblockContact}
            />
          )}

          {isAddQuickChatOpen && (
            <AddQuickChat
              setIsAddQuickChatOpen={setIsAddQuickChatOpen}
              handleCreateQuickChat={handleCreateQuickChat}
            />
          )}

          {isQcModalOpen && (
            <QcModal
              qcLink={qcLinkRef.current}
              setIsQcModalOpen={setIsQcModalOpen}
              handleShareBtn={handleShareQcLink}
            />
          )}

          {isAddNewChatOpen && (
            <AddNewChat
              setIsAddNewChatOpen={setIsAddNewChatOpen}
              setIsAddNewContactOpen={setIsAddNewContactOpen}
              contacts={contacts}
              handleSelectContact={handleStartNewChat}
              handleInviteContact={handleInviteContact}
              handleCreateCallOnContactClick={handleCreateCallOnContactClick}
            />
          )}

          {isAddNewContactOpen && (
            <AddNewChatContact
              setIsAddNewContactOpen={setIsAddNewContactOpen}
              setLoading={setLoading}
              loadContacts={loadContacts}
              handleInviteContact={handleInviteContact}
            />
          )}

          <ToggleList toggle={toggle} setToggle={setToggle} />

          {toggle === MESSAGES_LIST && (
            <ConversationsList
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              conversations={conversations}
              selectedConversation={selectedConversation}
              handleSelectConversation={handleSelectConversation}
              setLoading={setLoading}
              loadConversations={loadConversations}
            />
          )}

          {toggle === CALLS_LIST && (
            <CallsList
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              calls={calls}
              selectedCall={selectedCall}
              handleSelectCall={handleSelectCall}
              handleCreateCall={handleCreateCall}
              loadCalls={loadCalls}
              setLoading={setLoading}
            />
          )}
        </Left>

        <Right>
          <CallModal
            open={callModalOpen}
            handleClose={handleCloseCallModal}
            handleAnswer={handleAnswerIncomingCall}
            callDetails={incomingCallDetails}
          />

          {selectedCall && (
            <FullScreenCall
              data={selectedCall}
              callAccess={selectedCallAccess}
              resetCall={resetCall}
            />
          )}

          {selectedConversation ? (
            <RightWrapper>
              <ConversationWrapper>
                <ConversationHeader
                  selectedConversation={selectedConversation}
                  onClick={handleShowConversationDetailsMenu}
                  handleCreateCall={handleCreateCallHeaderClick}
                />
                <ConversationView
                  key={"View"}
                  shouldScrollBottom={shouldScrollBottom}
                  inputValue={messageInput}
                  handleInputChange={handleMessageInputChange}
                  handleSendButton={handleSendButton}
                  messages={currentConversationMessages}
                  selectedConversation={selectedConversation}
                  handleReactToMessage={handleReactToMessage}
                  handleShowFullScreenImg={handleShowFullScreenImg}
                  handleDeleteMessage={handleDeleteMessage}
                  displayRightClickMenu={displayRightClickMenu}
                  mediaInputFileRef={mediaInputFileRef}
                  fileInputRef={fileInputRef}
                  scrollHeightRef={scrollHeightRef}
                  setMediaInput={setMediaInput}
                  setFileInput={setFileInput}
                  mediaInput={mediaInput}
                  showEditMessageModal={showEditMessageModal}
                  selectedMessageForContextMenu={selectedMessageForContextMenu}
                  handleCloseEdit={handleCloseEdit}
                  messageInputRef={messageInputRef}
                  showReplyMessageModal={showReplyMessageModal}
                  handleCloseReply={handleCloseReplyMessageOption}
                  fileInput={fileInput}
                  shouldLoadMoreMessages={shouldLoadMoreMessages}
                  handleGetMoreMessages={handleGetMoreMessages}
                  showBlockedChatModal={showBlockedChatModal}
                  setShowBlockedChatModal={setShowBlockedChatModal}
                  handleUnblockContact={handleUnblockContact}
                  messageMood={messageMood}
                  setMessageMood={setMessageMood}
                />
                {mediaInput && (
                  <SendMedia
                    mediaInput={mediaInput}
                    setMediaInput={setMediaInput}
                    handleSendMedia={handleSendMediaButton}
                    selectedConversation={selectedConversation}
                    mediaInputFileRef={mediaInputFileRef}
                    inputValue={messageInput}
                    messageInputRef={messageInputRef}
                    handleInputChange={handleMessageInputChange}
                  />
                )}

                {fileInput && (
                  <SendFile
                    fileInput={fileInput}
                    setFileInput={setFileInput}
                    handleSendFileButton={handleSendFileButton}
                    selectedConversation={selectedConversation}
                    fileInputRef={fileInputRef}
                    inputValue={messageInput}
                    messageInputRef={messageInputRef}
                    handleInputChange={handleMessageInputChange}
                  />
                )}
              </ConversationWrapper>

              {showGroupChatDetails && !selectedConversation.with_emotii && (
                <ChatDetailsWrapper>
                  <ChatDetailsHeader
                    selectedConversation={selectedConversation}
                    handleClose={() => handleShowGroupChatDetails()}
                  />
                  {selectedConversation.group ? (
                    <GroupDetailsView
                      groupData={selectedConversation}
                      setConversations={setConversations}
                      setSelectedConversation={setSelectedConversation}
                      contacts={contacts}
                      setLoading={setLoading}
                      loadContacts={loadContacts}
                      handleInviteContact={handleInviteContact}
                    />
                  ) : (
                    <ChatDetailsView
                      conversationData={
                        selectedConversation.other_participants[0]
                      }
                      handleBlockContact={handleBlockContact}
                      handleUnblockContact={handleUnblockContact}
                    />
                  )}
                </ChatDetailsWrapper>
              )}

              {showQuickChatDetails && selectedConversation.open && (
                <ChatDetailsWrapper>
                  <ChatDetailsHeader
                    selectedConversation={selectedConversation}
                    handleClose={() => handleShowQcChatDetails()}
                  />
                  <QcChatDetailsView
                    conversationData={selectedConversation}
                    handleShareBtn={handleShareQcLink}
                    handleCloseChatBtn={handleCloseChatBtn}
                  />
                </ChatDetailsWrapper>
              )}

              {
                showMessageDetailsModal && (
                  <ChatDetailsWrapper>
                    <ChatDetailsHeader
                      selectedConversation={selectedConversation}
                      handleClose={() => handleShowMessageInfoModal()}
                    />
                    <MessageDetailsModal
                      selectedMessage={selectedMessageForContextMenu}
                      selectedConversation={selectedConversation}
                    />
                  </ChatDetailsWrapper>

                )
              }


            </RightWrapper>
          )
            :
            (
              <EmptyConversation />
            )}
        </Right>

        {showFullScreenImg && (
          <FullscreenImage
            selectedConversation={selectedConversation}
            handleDeletePhoto={handleDeletePhoto}
            image={fullScreenImg.image}
            conversationId={fullScreenImg.conversationId}
            messageId={fullScreenImg.messageId}
            handleCloseFullScreenImg={handleCloseFullScreenImg}
          />
        )}

        {showRatingCard && (
          <RatingModal
            sendRating={handleSendRating}
            setShowRatingCard={setShowRatingCard}
            showRatingCard={showRatingCard}
          />
        )}

        {showForwardModal && (
          <ForwardModal
            handleClose={handleCloseForwardModal}
            chats={conversations.filter((conv) => !conv.with_emotii)}
            contacts={contacts}
            contextMenuItem={selectedMessageForContextMenu}
          />
        )}
        {isSubscriptionModalOpen && (
          <SubscriptionModal
            handleClose={handleCloseSubscriptionModal}
            handleSubscribeClick={handleSubscribeClick}
            handleUnSubscribeClick={handleUnSubscribeClick}
          />
        )}

        {deleteReasonModalOpen && (
          <DeleteReasonsModal
            handleClose={closeDeleteReasonModal}
            setDeleteConfirmationOpen={setDeleteConfirmationOpen}
            setSelectedReasons={setSelectedReasons}
            selectedReasons={selectedReasons}
          />
        )}


        {((!selectedConversation?.with_emotii &&
          selectedConversation?.quick_chat &&
          selectedConversation?.open) ||
          (!selectedConversation?.with_emotii &&
            !selectedConversation?.quick_chat)) &&
          !isSimpleAndBlockedConversation(selectedConversation) && (
            <RightClickMenu
              handleDeleteMessage={handleDeleteMessage}
              handleReportMessage={handleReportMessage}
              handleForwardMessage={handleForwardMessage}
              handleEditMessageOption={handleEditMessageOption}
              handleReplyMessageOption={handleReplyMessageOption}
              handleCopyOption={handleCopyOption}
              contextMenuItem={selectedMessageForContextMenu}
              setShowForwardModal={setShowForwardModal}
              selectedConversation={selectedConversation}
              user={user}
              conversations={conversations}
              currentConversationMessages={currentConversationMessages}
              handleInfoOption={handleOpenMessageInfoModal}
            />
          )}
      </Container>
    </FirstWrapper>
  );
};

export default ChatPage;
const FirstWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1440px) {
    margin: 0;
    padding: 0;
  }
`;
const Container = styled.div`
  display: flex;
  min-width: 600px;
  overflow: hidden;
  height: 100vh;
  max-height: calc(100vh - 40px);
  @media (max-width: 1440px) {
    max-height: 100vh;
  }
  width: 100%;
  position: relative;
  border: 1px solid ${colors.chatBg};
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 460px;
  min-width: 400px;
  width: 100%;
  background: ${colors.chatsListBg};
  position: relative;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const ConversationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 400px;
`;
const ChatDetailsWrapper = styled.div`
display:flex;
flex-direction:column;
  width: 100%;
  max-width: 350px;
  position: relative;
  border-left: 1px solid ${colors.chatBg};
  background: ${colors.chatsListBg};
`;

const RightWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;
