import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { displayAvatar } from "../../Helpers/chatHelper/AvatarHelper";
import ControlButton from "./ControlButton";

import { ReactComponent as MicOff } from "../../Assets/icons/calls/micOn.svg";
import { ReactComponent as MuteSound } from "../../Assets/icons/calls/muteSound.svg";
import { ReactComponent as CloseCall } from "../../Assets/icons/calls/closeCall.svg";

import { displayName } from "../../Helpers/chatHelper/NameHelper";
import callingSound from "../../Assets/sounds/ring.wav";
import connectedSound from "../../Assets/sounds/connected.mp3";
import { Howl, Howler } from "howler";

import {
  LiveKitRoom,
  useRoomContext,
  RoomAudioRenderer,
  useRemoteParticipants,
  useConnectionState,
} from "@livekit/components-react";

import { useWebSocketDataService } from "../../Context/WebsocketContext";
import { useAuthService } from "../../Context/AuthContext";

export const callingSoundHowl = new Howl({
  src: [callingSound],
  loop: true,
});
export const connectedSoundHowl = new Howl({
  src: [connectedSound],
});

const MyComponent = ({ data, resetCall, sendCloseCallMessage }) => {
  const room = useRoomContext();
  const remoteParticipant = useRemoteParticipants();

  const [micEnabled, setMicEnabled] = useState(
    room.localParticipant.isMicrophoneEnabled
  );

  const [audioEnabled, setAudioEnabled] = useState(false);
  const status = useConnectionState();

  const togleMic = () => {
    const enabled = room.localParticipant.isMicrophoneEnabled;
    room.localParticipant.setMicrophoneEnabled(!enabled);
    setButtons();
  };

  const togleAudio = () => {
    const localRemoteParticipant = remoteParticipant[0];
    const audioPublication =
      localRemoteParticipant?.getTrackPublications("audio")[0];

    // const muted = audioPublication?.audioTrack?.isMuted;

    if (audioPublication !== undefined) {
      audioPublication.audioTrack?.setMuted(audioEnabled);
    }
  };

  const setButtons = () => {
    const enabled = room.localParticipant.isMicrophoneEnabled;
    setMicEnabled(enabled);
  };

  const handleDisconect = (e) => {
    // console.log(data, "data");
    e.preventDefault();
    sendCloseCallMessage(data.uuid);
    callingSoundHowl.stop();
    room.disconnect();
    resetCall();
  };

  const displayStatus = () => {
    if (remoteParticipant.length == 0) {
      return "Connecting";
    }

    if (remoteParticipant.length > 0) {
      return "Connected";
    }
  };

  useEffect(() => {
    if (callingSoundHowl.playing() === true && remoteParticipant.length > 0) {
      callingSoundHowl.stop();
      connectedSoundHowl.play();
    }
  }, [remoteParticipant]);

  useEffect(() => {
    togleAudio();
  }, [audioEnabled, remoteParticipant]);

  return (
    <Content>
      <div id="participants-area"></div>
      <UserAvatar style={data?.quick_chat ? { border: "none" } : {}}>
        {displayAvatar(data)}
      </UserAvatar>
      <UserName>{displayName(data)}</UserName>
      <ConnectionStatus>{displayStatus()}</ConnectionStatus>

      <ControlGroup>
        <ControlButton
          active={micEnabled}
          icon={<MicOff />}
          onClick={() => {
            togleMic();
          }}
        />
        <ControlButton
          active={audioEnabled}
          icon={<MuteSound />}
          onClick={() => {
            setAudioEnabled(!audioEnabled);
          }}
        />
      </ControlGroup>
      <ControlGroup style={{ marginTop: "150px" }}>
        <ControlButton
          icon={<CloseCall />}
          onClick={(e) => {
            handleDisconect(e);
          }}
        />
      </ControlGroup>
    </Content>
  );
};

const FullScreenCall = ({ data, callAccess, resetCall }) => {
  const { sendCloseCallMessage } = useWebSocketDataService();

  return (
    <FullscreenContainer>
      <LiveKitRoom
        serverUrl={callAccess?.endpoint}
        token={callAccess?.token}
        audio={true}
      >
        <RoomAudioRenderer />
        <MyComponent
          data={data}
          resetCall={resetCall}
          sendCloseCallMessage={sendCloseCallMessage}
        />
      </LiveKitRoom>
    </FullscreenContainer>
  );
};

export default FullScreenCall;

const FullscreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${colors.background};
  z-index: 1;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  min-width: 200px;
  min-height: 400px;
  height: fit-content;
  width: 100%;
  padding: 50px 45px;
`;

const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 80px;
  max-width: 80px;
  object-fit: contain;
  position: relative;
  min-width: 80px;
  min-height: 80px;
  overflow: hidden;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
    position: relative;
    height: 80px;
    width: 80px;
  }
`;

const UserName = styled.h1`
  color: ${colors.white};
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
`;
const ConnectionStatus = styled.p`
  font-size: 21px;
  font-weight: 400;
  line-height: 25.06px;
  text-align: center;
  color: ${colors.chatElementColor};
`;

const ControlGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
