import { useOnlineStatus } from "../useOnlineStatus";
import emotiiWebSocket from "./emotiiWebSocket";

class WebSocketHandler {
  static CHANNEL_NAMES = ["MessagesChannel", "CallChannel"];

  constructor() {
    this.ws = new emotiiWebSocket();
    this.observers = [];
  }

  connect() {
    this.ws.connect();
    this.#subscribeToChannels();
  }

  disconnect() {
    this.ws.disconnect();
  }

  // WebSocketObserver
  addObserver(observer) {
    this.observers.push(observer);
  }

  // WebSocketObserver
  removeObserver(observer) {
    this.observers = this.observers.filter((obs) => obs !== observer);
  }

  sendCloseCallMessage(callUuid) {
    const dataObject = {
      call_uuid: callUuid,
      update: "close",
    };
    this.#sendCallMessage(dataObject);
  }

  sendDenyTranslationMessage(callUuid) {
    const dataObject = {
      call_uuid: callUuid,
      update: "deny_translations",
    };
    this.#sendCallMessage(dataObject);
  }

  // Private methods

  #sendCallMessage(dataObject) {
    this.ws.sendMessage("CallChannel", dataObject);
  }

  #notify(type, message) {
    this.observers.forEach((observer, index) => {
      if (observer.channelName === type) {
        observer.update(message);
      }
    });
  }

  #subscribeToChannels() {
    const self = this;
    WebSocketHandler.CHANNEL_NAMES.forEach((name, index) => {
      this.ws.subscribeToChannel(name, (type, message) => {
        this.#notify(type, message);
      });
    });
  }
}

export default WebSocketHandler;
