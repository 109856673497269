

export const EN = {
    language_name: "English",
    language_code: "EN",
    login: {
        login_with_phone: "Login with your phone number",
        login_agree: "By Loging In you agree with our",
        terms_and_conditions: "Terms and Conditions",
        enter_code: "Enter the code we just sent you",
        wrong_validation_code: "Sorry, the validation code is incorrect. Please try with another code.",
        code_not_received: "Didn’t receive any code?",
        resend_code: "Resend code",
        app_language: "Select app language",
        verify_code: "Verify Code",
        continue: "Continue",
        code_sent: "Code sent"
    },

    register: {
        next_button: "Next",
        your_name: "Your Name",
        name_details: "Tell us how should other people on the platform call you",
        name_placeholder: "Type your name...",
        chat_language: "Chat language",
        language_details: "You will receive all the messages in the chosen language.",
        birthdate: "Birthdate",
        birthdate_details: "We'll use your birthdate to make message translations better tailored to you.",
        dont_want_to_share: "Don't want to share",
        gender: "Gender",
        gender_details: "We'll take your gender into account to further personalize your communication.",
        cultural_background: "Cultural background",
        cultural_background_details: "We use your cultural background information, to fine-tune your communication.",
    },

    profile: {
        my_profile: "My profile",
        back: "Back",
        personal_details: "PERSONAL DETAILS",
        chat_settings: "CHAT SETTINGS",
        account_details: "ACCOUNT DETAILS",
        others: "OTHERS",
        language: "Language",
        birthday: "Birthday",
        gender: "Gender",
        cultural_background: "Cultural Background",
        phone_number: "Phone Number",
        logout: "Logout",
        delete_account: "Delete Account",
        term_and_conditions: "Terms & Conditions",
        privacy: "Privacy Policy",
        support: "Support",
        please_select_language: "Please select language",
        please_select_gender: "Please select your gender",
        type_cultural_background: "Type your cultural background",
        select_birthdate: "Select birthdate",
        type_your_status: "Type your status",
        not_connected: "You’re not connected to the internet, please check your connection!",
        something_wrong: "Something wrong",
        cant_have_conv_with_you: "You can't have conversation with yourself",
        profile_updated: "Profile updated",
        version: "Version",
        delete_account_info: "Are you sure you want to delete your account? This will delete all your data.",
        invite_new_contact: "Invite new contact",
        account_closure: "ACCOUNT CLOSURE",
        block_users: "Block users",
        unknown_users: "UNKNOWN USERS",
        block_unknown_users: "Block unknown users",
        subscription: "Subscription",
        unsubscribe: "Unsubscribe",
        revokeAdmin: "Revoke",
        unsubscribeConfirmationMessage: "Are you sure you want to unsubscribe? You’ll loose access to all the benefits",
        revokeAdminConfirmationMessage: "Are you sure you want to revoke admin rights for this member?",
        deleteChat: "Are you sure you want to remove this conversation?"
    },

    chat: {
        was_invited: "was invited",
        invited_to_conversation: "This phone number does not have an emotii account, we’ve sent them an SMS message to join the platform. Once they’ve created an account, you can begin to chat.",
        speak_in_your_language: "Speak in your language, we'll handle the rest!",
        new: "New",
        original_message: "Original Message",
        preffered_language: "Preferred Language",
        image: "Image",
        search: "Search",
        message_deleted: "Message deleted",
        type_your_message: "Type your message",
        send_image: "Send Image",
        send_media: "Send Media",
        send_file: "Send File",
        send_to: "Send to",
        close: "Close",
        rating_modal_message: "How good are the translations in this conversations?",
        remove_member: " Are you sure you want to remove this member?",
        rating_sent: "Rating sent",
        original_in: "Original in",
        forward: "Forward",
        reply: "Reply",
        copy: "Copy",
        edit: "Edit",
        report: "Report",
        info: "Info",
        delete: "Delete",
        forward_to: "Forward To",
        forward_message: "Forward Message",
        chats: "Chats",
        add_caption: "Add a caption",
        file: "File",
        video: "Video",
        deleted: "Deleted",
        generated_qc_link: "Generated Quick Chat Link",

    },

    new_chat: {
        new_chat: "New Chat",
        new_group: "New Group",
        group: "Group",
        chat: "Chat",
        start_chat: "Start chat",
        name: "NAME",
        first_name: "First name",
        last_name: "Last Name",
        search_member: "Search Member",
        add_member: "Add Member",
        found_contact: "Found Contact",
        members: "Members",
        enter_group_name: "Enter group name",
        contact_not_emotii_user: "Contact is not an Emotii user",
        create: "Create",
        create_group: "Create Group",
        invite_sent: "Invite sent",
        add_members: "Add Members",
        contacts_on_emotii: "Contacts on emotii",
        invite_to_emotii: "Invite to emotii",
        new_contact: "New Contact",
        save: "Save",
        members_added: "Members added",
        add_contact: "Add Contact",
        contacts: "Contacts",
        cant_have_conv_with_u: "You can’t have a conversation with yourself.",
        quick_chat: "Quick Chat",
        quick_chat_placeholder: "Quick chat name",
        new_quick_chat: "New Quick Chat",
        create_quick_chat: "Create Quick Chat"
    },

    quick_chat: {
        invited_by: "You're invited to join the",
        emotii_chat: "emotii chat",
        enter_name_and_language: "Enter your name and preferred language to start chatting.",
        your_name: "Your name",
        chat_language: "Chat Language",
        caution: "Be careful: anyone with this link can see your quick chat conversations. Share it only with the people you want to chat with. Use quick chats temporarily and make sure to close them when you're done by tapping the close button below",
        cautionModal: "Be careful: anyone with this link can see your quick chat conversations. Share it only with the people you want to chat with. Use quick chats temporarily and make sure to close them when you're done by tapping the name in the header and pressing the close button.",
        caution_close_qc: "Are you sure you want to end this quick chat? Once you do, it will no longer be accessible to the other participant.",
        cancel: "Cancel"
    },

    chat_details: {
        group_details: "Group Details",
        user_details: "User details",
        account_details: "Account details",
        save_changes: "Save changes",
        details: "Details",
        exit_group: " Are you sure you want to exit this group?",
        yes: "Yes",
        no: "No",
        member_added: "Member added",
        generated_link: "GENERATED LINK",
        message_details: "Message Details",
        delivered_to: "Delivered to",
        read_by: "Read by",
        make_admin: "Make Admin",
        revoke_admin: "Revoke Admin",
        remove_user: "Remove user",
        leave_group: "Leave group"

    },

    context_menu: {
        delete_chat: "Delete chat",
        hide_chat: "Hide chat",
        delete_call: "Delete call",
        delete_call_message: "Are you sure you want to delete this call?",
        message_mood_info_text: "The mood of this message is"
    },

    block_contacts: {
        blocked: "BLOCKED",
        blocked_contact: "Blocked contact",
        blocked_contact_modal_text: "You have blocked this contact. To send a message, please unblock them by pressing the button below. Note that they will be able to contact you once unblocked.",
        block: "Block",
        unblock: "Unblock",
        unblocked: "UNBLOCKED",
        unblockConfirmationMessage: "This action will allow this user to contact you. Are you sure you want to proceed?",
        blockConfirmationMessage: "Blocking this user will prevent them from contacting you. Are you sure you want to proceed?"
    }

}