import React from 'react'
import PhoneInput from "react-phone-number-input";
import styled from 'styled-components'
import "../../Assets/styles/customPhoneSelect.css"
import 'react-phone-number-input/style.css'
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as PhoneInputIcon } from '../../Assets/icons/enterPhoneNumberIcon.svg';
import { localizationStrings } from '../../Localization/Localization';

const EnterPhoneNumber = ({ phone, setPhone, setCountryCode, onSigninSubmit, defaultCountry }) => {
    const navigateInNewTab = (to) => {
        // let url = window.location.origin
        // window.open(`${url}/${to}`)
        window.open("https://emotii.ai/emotii-platform-terms-and-conditions/")
    }
    return (
        <Container>
            <LeftSide>
                <Card>
                    <Group>
                        <h1>{localizationStrings.login.login_with_phone}</h1>
                        <CustomPhoneWrapper>
                            <PhoneInput
                                international
                                defaultCountry={defaultCountry}
                                name="phoneNumber"
                                placeholder={localizationStrings.profile.phone_number}
                                value={phone}
                                onChange={(e) => setPhone(e)}
                                onCountryChange={(e) => setCountryCode(e)}
                                limitMaxLength
                            />
                        </CustomPhoneWrapper>
                    </Group>
                    <Group>
                        <LoginButton
                            disabled={!phone || phone?.length < 8}
                            id='sign-in-button'
                            onClick={(e) => onSigninSubmit(e)}>
                            {localizationStrings.login.continue}
                        </LoginButton>
                        <TermsInfo onClick={() => navigateInNewTab("terms-and-conditions")}>
                            <p>{localizationStrings.login.login_agree}<br />
                                <span >{localizationStrings.login.terms_and_conditions}</span>
                            </p>
                        </TermsInfo>
                    </Group>
                </Card>
            </LeftSide>
            <RightSide>
                <PhoneInputIcon />
            </RightSide>


        </Container>
    )
}

export default EnterPhoneNumber
const Container = styled.div`
display:flex;
min-height:600px;
max-height:100vh;
height:100%;
width:100%;
`
const Card = styled.div`
display:flex;
flex-direction:column;
max-width:400px;
width:100%;
gap:20px;
`
const CustomPhoneWrapper = styled.div`
background: ${colors.newChatModalInputBg};
padding:0 0 0 16px;
border-radius:10px;
display:flex;
align-items:center;
`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
h1{
color:${colors.white};
font-size: 32px;
font-weight: 600;
line-height: 39px;
letter-spacing: 0px;
text-align: left;
}
  input {
    
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;
const LoginButton = styled.button`
display: flex;
align-items: center;
justify-content:center;
gap: 3px;
background: ${colors.purple};
color: ${colors.white};
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: center;
border: none;
border-radius: 10px;
padding: 14px 15px;
cursor: pointer;
svg {
  height: 20px;
  width: 20px;
}
&:disabled{
    opacity:0.5;
}
`;
const LeftSide = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:center;
padding:0 32px;
`
const RightSide = styled(LeftSide)`
`

const TermsInfo = styled.div`
display:flex;
align-items:center;
justify-content:center;
p{
color:#B5B8CD;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    
}

span{
    cursor:pointer;
    color:${colors.purple};
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    text-decoration:underline;
}
`